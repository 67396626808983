import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'next-i18next';
import Collapse from '@mui/material/Collapse';
import { useState } from 'react';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import classNames from 'classnames';

export default function FiltersByTypes({
  data,
  isSuccess,
  isLoading,
  isError,
  onChangeTypes,
  types,
  collapsed = false,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(!collapsed);

  if (isLoading) {
    return (
      <div className="flex items-end justify-end w-full">
        <CircularProgress thickness={4} size={20} />
      </div>
    );
  }

  if (isSuccess && data?.length > 0) {
    return (
      <div className="flex flex-col">
        <button
          onClick={() => setOpen(!open)}
          className={classNames(
            'relative',
            'w-full tracking-widest flex justify-end items-center',
            'text-right font-semibold uppercase space-x-1',
            {
              'text-primary': open,
              'opacity-50': !open,
            },
          )}
        >
          {open ? (
            <UnfoldLessIcon fontSize="small" />
          ) : (
            <UnfoldMoreIcon fontSize="small" />
          )}

          <span>{t('generic.labels.type')}</span>
        </button>

        <Collapse in={open} timeout="auto">
          <FormGroup className="pr-1.5 pt-4">
            {sortBy(data, 'name')?.map((f, i) => {
              const { slug, name } = f;
              return (
                <FormControlLabel
                  key={i}
                  label={name}
                  labelPlacement="start"
                  className="text-sm text-gray-700 text-end dark:text-white"
                  control={
                    <Checkbox
                      size="small"
                      value={JSON.stringify({
                        slug,
                        name,
                      })}
                      className="p-1"
                      onChange={onChangeTypes}
                      checked={
                        types?.filter((t) => t?.slug === slug)
                          ?.length > 0
                      }
                    />
                  }
                />
              );
            })}
          </FormGroup>
        </Collapse>
      </div>
    );
  }

  if (isError) return null;

  return <></>;
}
