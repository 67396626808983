import {
  Footer,
  Navbar,
  BackTopButton,
  Breadcrumbs,
  DrawerMenu,
} from '@components';
import classNames from 'classnames';
import { ReactNode } from 'react';

type ArticleLayoutProps = {
  children?: ReactNode[];
  head?: ReactNode;
  isHome?: boolean;
  showBreadcrumbs?: boolean;
  showHero?: boolean;
  toolbar?: ReactNode;
};

export default function ArticleLayout({
  head,
  children,
  toolbar,
  showBreadcrumbs = true,
}: ArticleLayoutProps): ReactNode {
  return (
    <div
      className={classNames(
        'relative layout default-layout',
        'min-h-screen',
      )}
    >
      <Navbar />
      <div className="relative">{head}</div>
      {toolbar && toolbar}
      {showBreadcrumbs && <Breadcrumbs />}
      <main
        className={classNames(
          'container relative',
          'flex flex-col',
          'w-auto h-full px-5 mx-auto pb-16',
        )}
      >
        <div className="max-w-4xl py-5 mx-auto">{children}</div>
      </main>
      <Footer />
      <BackTopButton />
      <DrawerMenu />
    </div>
  );
}
