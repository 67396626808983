import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import lowerCase from 'lodash/lowerCase';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Collapse from '@mui/material/Collapse';
import { useState } from 'react';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

export default function FiltersByCities({
  data,
  isSuccess,
  isLoading,
  isError,
  onChangeCity,
  onChangeRegion,
  regions,
  locations,
  collapsed = false,
}) {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(!collapsed);

  const [collapsedCities, setCollapsedCities] = useState([]);

  // Function utility: group cities by region
  const groupedByRegion = (cities) => {
    if (cities?.length > 0) {
      // Sort city by region
      const sortedCity = sortBy(cities, 'attributes.region');
      // Then group them by region
      const groupedByCity = groupBy(
        sortedCity,
        'attributes.region',
      );
      const regions = Object.entries(groupedByCity);
      const list = Object.values(groupedByCity);

      return regions.map((region, i) => {
        return {
          slug: region[0],
          name: region[0],
          list: sortBy(list[i], 'attributes.name'),
        };
      });
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-end justify-end w-full">
        <CircularProgress thickness={4} size={20} />
      </div>
    );
  }

  if (isSuccess && data?.length > 0) {
    return (
      <div className="flex flex-col">
        {/* <code>{JSON.stringify({ collapsedCities })}</code> */}

        <button
          onClick={() => setOpen(!open)}
          className={classNames(
            'relative',
            'mb-3 w-full tracking-widest flex justify-end items-center',
            'text-right font-semibold uppercase space-x-1',
            {
              'text-primary': open,
              'opacity-50': !open,
            },
          )}
        >
          {open ? (
            <UnfoldLessIcon fontSize="small" />
          ) : (
            <UnfoldMoreIcon fontSize="small" />
          )}

          <span>{t('generic.labels.cities')}</span>
        </button>

        <Collapse in={open} timeout="auto">
          <ul className="flex flex-col pr-1.5 space-y-3">
            {groupedByRegion(data)?.map(
              ({ slug, name, list }, y) => {
                return (
                  <li className="relative text-right" key={y}>
                    <FormGroup className="relative mb-2 text-sm">
                      <FormControlLabel
                        className="flex items-center"
                        label={
                          <div className="flex items-center justify-end max-w-xl">
                            <button
                              onClick={(e) => {
                                e?.preventDefault();
                                e?.stopPropagation();
                                if (
                                  collapsedCities.includes(name)
                                ) {
                                  setCollapsedCities([
                                    ...collapsedCities.filter(
                                      (c) => c !== name,
                                    ),
                                  ]);
                                } else {
                                  setCollapsedCities([
                                    ...collapsedCities,
                                    name,
                                  ]);
                                }
                              }}
                              className={classNames(
                                'w-6 h-6 flex justify-center items-center pr-1.5 text-sm',
                                'opacity-40',
                              )}
                            >
                              {collapsedCities.includes(name) ? (
                                <UnfoldMoreIcon fontSize="small" />
                              ) : (
                                <UnfoldLessIcon fontSize="small" />
                              )}
                            </button>
                            <span
                              className={classNames(
                                'font-title relative',
                                'text-right text-lg font-medium',
                                {
                                  'opacity-50':
                                    collapsedCities.includes(name),
                                },
                              )}
                            >
                              {name}
                            </span>
                          </div>
                        }
                        labelPlacement="start"
                        control={
                          <Checkbox
                            size="small"
                            onChange={() =>
                              onChangeRegion({
                                slug,
                                name,
                                list,
                              })
                            }
                            className="p-1 text-sm"
                            value={name}
                            checked={
                              regions?.filter(
                                (r) =>
                                  lowerCase(r) === lowerCase(name),
                              )?.length > 0
                            }
                          />
                        }
                      />
                    </FormGroup>

                    <div>
                      <Collapse
                        timeout="auto"
                        in={
                          collapsedCities.filter((c) => c === name)
                            ?.length === 0
                        }
                      >
                        {list?.map(({ attributes }, i) => {
                          const { slug, name, region } = attributes;
                          return (
                            <FormGroup
                              key={i}
                              className="text-sm pr-1.5 "
                            >
                              <FormControlLabel
                                label={name}
                                labelPlacement="start"
                                className="text-sm text-gray-700 dark:text-white"
                                control={
                                  <Checkbox
                                    size="small"
                                    onChange={onChangeCity}
                                    className="p-1 text-sm"
                                    value={JSON.stringify({
                                      slug,
                                      name,
                                      region,
                                    })}
                                    checked={
                                      locations?.filter(
                                        (l) => l?.slug === slug,
                                      )?.length > 0
                                    }
                                  />
                                }
                              />
                            </FormGroup>
                          );
                        })}
                      </Collapse>
                    </div>
                  </li>
                );
              },
            )}
          </ul>
        </Collapse>
      </div>
    );
  }

  if (isError) return null;

  return null;
}
