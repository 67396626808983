import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useMediaQueries } from '@hooks';
import Chip from '@mui/material/Chip';
import capitalize from 'lodash/capitalize';

export default function FiltersChips({
  chips,
  onDeleteFilterChip,
}) {
  const { isMobile } = useMediaQueries();

  if (isMobile) {
    return (
      <Splide
        className="w-full"
        options={{
          rewind: true,
          gap: '0.2rem',
          arrows: false,
          autoWidth: true,
          pagination: false,
        }}
      >
        {chips?.length > 0 &&
          chips.map((val, i) => {
            const { slug, name, region } = val;
            return (
              <SplideSlide className="w-auto" key={i}>
                <Chip
                  key={slug}
                  variant="outlined"
                  label={capitalize(name)}
                  className="mb-3 mr-2 md:mr-3"
                  onDelete={() =>
                    onDeleteFilterChip({
                      slug,
                      name,
                      region,
                    })
                  }
                />
              </SplideSlide>
            );
          })}
      </Splide>
    );
  }

  return (
    <div className="flex flex-wrap w-full">
      {chips?.length > 0 &&
        chips.map(({ slug, name, region }) => {
          return (
            <Chip
              key={slug}
              variant="outlined"
              label={name}
              className="mb-3 mr-2 md:mr-3"
              onDelete={() =>
                onDeleteFilterChip({
                  slug,
                  name,
                  region,
                })
              }
            />
          );
        })}
    </div>
  );
}
