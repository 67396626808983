import {
  Footer,
  Navbar,
  Breadcrumbs,
  DrawerMenu,
  BackTopButton,
  AuthWall,
  LoginDialog,
  
} from '@components';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { userSelector } from '@slices';

import dynamic from 'next/dynamic';

const Newsletter = dynamic(() =>
  import("./../../components/Sections/Newsletter/Newsletter"),
  { ssr: false }
);



export default function DefaultLayout({
  head,
  children,
  toolbar,
  showBreadcrumbs = true,
  enableAuthWall = false,
  mobileFixedBtn = null,
  mobileFixedMessage,
  mobileFixedCta,
  isQuiz = false,
  dense = true,
  className,
  showNewsletter = false,
}) {
  const { logged } = useSelector(userSelector);

  return (
    <div
      className={classNames(
        className,
        'layout default-layout',
        'relative min-h-screen',
      )}
    >
      <Navbar />
      <div className="relative">{head}</div>
      {toolbar && toolbar}
      {showBreadcrumbs && <Breadcrumbs />}
      <main
        className={classNames(
          'main relative flex flex-col',
          'h-full w-auto',
          'text-gray-700 dark:text-white',
          {
            'container mx-auto px-4 lg:px-10 3xl:px-8': dense,
          },
        )}
      >
        {[
          // Public
          !enableAuthWall && children,
          // Allowed
          enableAuthWall && logged && children,
          // Protected
          enableAuthWall && !logged && <AuthWall />,
        ]}

        {isQuiz && (
          <span
            className={classNames(
              'fixed left-0 top-0 h-screen w-screen',
              'z-[-5] bg-quiz bg-contain bg-fixed',
              'opacity-10 dark:opacity-30 dark:mix-blend-soft-light ',
            )}
          />
        )}
      </main>

      {showNewsletter && <Newsletter/>}

      <Footer hasMobileCta={mobileFixedCta} />
      <BackTopButton />
      <DrawerMenu />
      <LoginDialog />

      {mobileFixedMessage?.length > 0 && (
        <div
          className={classNames(
            'h-12 md:h-14',
            'fixed bottom-0 left-0 right-0',
            'flex items-center justify-center',
            'z-10 bg-red-600 lg:hidden',
          )}
        >
          <span className="text-sm text-white">
            {mobileFixedMessage}
          </span>
        </div>
      )}

      {typeof mobileFixedBtn === 'function' &&
        mobileFixedBtn &&
        mobileFixedCta && (
          <div
            className={classNames(
              'bg-primary',
              'h-12 md:h-14',
              'fixed bottom-0 left-0 right-0 z-50',
              'flex items-center justify-center lg:hidden',
            )}
          >
            {/* Bad workaround to avoid strange scroll to button position after click */}
            <span
              onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();
                mobileFixedBtn();
              }}
              className={classNames(
                'h-full w-full',
                'flex items-center justify-center',
                'text-sm uppercase text-white',
                'pointer-events-auto',
              )}
            >
              {mobileFixedCta || 'CTA'}
            </span>
          </div>
        )}
    </div>
  );
}
