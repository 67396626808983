import { useEffect, useState } from 'react';
import FiltersByCities from './../Filters/FiltersByCities';
import FiltersByTypes from './../Filters/FiltersByTypes';
import FiltersChips from './../Filters/FiltersChips';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { NextSeo } from 'next-seo';
import TuneIcon from '@mui/icons-material/Tune';
import { Virtuoso } from 'react-virtuoso';
import { stringify } from 'qs';
import { useGetStrapiCitiesQuery } from '@services';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import lowerCase from 'lodash/lowerCase';
import uniqBy from 'lodash/uniqBy';
import unionBy from 'lodash/unionBy';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { useMediaQueries } from '@hooks';
import { DefaultLayout } from '@layouts';
import { DrawerFilters } from '@components';
import classNames from 'classnames';

export default function InfiniteScrollingClientProducts({
  seo: { title, description },
  Card,
  type,
  qsTypes,
  qsTag = null, //FOR SPECIAL TAGS ONLY
  qsRegion,
  useServiceFiltersQuery,
  useGetStrapiProductsQuery,
}) {
  const { locale } = useRouter();
  // i18n
  const { t } = useTranslation();
  // React hooks
  const [limit, setLimit] = useState(40);
  const [page, setPage] = useState(1);
  const [chips, setChips] = useState([]);
  // Filters
  const [locations, setLocations] = useState([]);
  const [types, setTypes] = useState([]);
  const [regions, setRegions] = useState([]);
  const [drawerFiltersOpen, setDrawerFiltersOpen] = useState(false);
  // Material UI hooks
  const { isMobile, isTablet, isDesktop } = useMediaQueries();

  // GET CITIES
  const {
    data: cities,
    isLoading: isCitiesLoading,
    isSuccess: isCitiesSuccess,
    isError: isCitiesError,
  } = useGetStrapiCitiesQuery({ locale });

  // GET FILTERS
  const {
    data: filters,
    isLoading: isFiltersLoading,
    isSuccess: isFiltersSuccess,
    isError: isFiltersError,
  } = useServiceFiltersQuery({ locale });

  //ONLY FOR SPECIAL EVENTS
  const customFilter =
    qsTag === '8_marzo'
      ? [
          'sull-altare-della-dea',
          'donne-nel-medioevo',
          'donne-nell-antichità',
          'la-donna-nella-societa',
        ]
      : [];

  // GET PRODUCTS
  const {
    data: products,
    isLoading: isProductsLoading,
    isFetching: isProductsFetching,
    isSuccess: isProductsSuccess,
    refetch: refetchProducts,
  } = useGetStrapiProductsQuery(
    {
      query: stringify(
        {
          pagination: {
            page: page,
            pageSize: limit,
          },
          filters: {
            city: {
              slug: {
                $eq: [...locations.map(({ slug }) => slug)],
              },
            },
            type: {
              slug: {
                $eq: [...types.map(({ slug }) => slug)],
              },
            },
            slug: {
              $eq: [...customFilter],
            },
          },
        },
        { encodeValuesOnly: true },
      ),
      locale,
    },
    { refetchOnMountOrArgChange: true },
  );

  // REFETCH on state change
  useEffect(() => {
    refetchProducts();
  }, [types, locations, refetchProducts, page]);

  useEffect(() => {
    const mergedSelected = [...uniqBy(locations, 'slug'), ...types];
    setChips(mergedSelected);
  }, [locations, types]);

  // TYPES: Update types based on query string: types
  useEffect(() => {
    if (qsTypes?.length > 0) {
      if (isFiltersSuccess && filters?.length > 0) {
        const filterTypesByQs = filters?.filter(
          (f) => f?.slug === qsTypes,
        );
        setTypes([...types, ...filterTypesByQs]);
      }
    }
  }, [qsTypes, isFiltersSuccess, filters]);

  // REGION: Update regions based on query string region
  useEffect(() => {
    if (qsRegion?.length > 0) {
      if (isCitiesSuccess && cities.length > 0) {
        setRegions([lowerCase(qsRegion)]);

        const filteredByRegion = cities?.filter(
          ({ attributes }) => {
            const { region } = attributes;
            return lowerCase(region) === lowerCase(qsRegion);
          },
        );

        setLocations([
          ...locations,
          ...filteredByRegion.map(({ attributes }) => {
            return {
              name: attributes?.name,
              slug: attributes?.slug,
              region: attributes?.region,
            };
          }),
        ]);
      }
    }
  }, [qsRegion, isCitiesSuccess, cities]);

  // FILTERs: reset
  const onResetFilter = () => {
    setLocations([]);
    setTypes([]);
    setRegions([]);
    setChips([]);
    window.history.replaceState(
      {},
      document.title,
      window.location.pathname,
    );
  };
  // ONDELETE CHIP
  const onDeleteFilterChip = ({ slug, name, region }) => {
    if (slug) {
      // Locations
      const locationsBySameSlug = locations.filter(
        (l) => l?.slug === slug,
      );
      const locationsDifferentSlug = locations.filter(
        (q) => q?.slug !== slug,
      );
      // Regions
      const regionsBySlug = regions.filter(
        (r) => lowerCase(r) !== lowerCase(region),
      );
      // Types
      const typesBySameSlug = types.filter((l) => l?.slug === slug);
      const typesByDifferentSlug = types.filter(
        (q) => q?.slug !== slug,
      );

      if (locationsBySameSlug?.length > 0) {
        setLocations([...locationsDifferentSlug]);
        setRegions([...regionsBySlug]);
      }

      if (typesBySameSlug?.length > 0) {
        setTypes([...typesByDifferentSlug]);
      }

      window?.history.replaceState(
        {},
        document.title,
        window?.location?.pathname,
      );
    }
  };

  // OPEN MOBILE FILTER DRAWER
  const onOpenDrawerFilters = () => {
    setDrawerFiltersOpen(true);
  };

  // FILTERs: by CITIES
  const onChangeCity = ({ target }) => {
    try {
      const { slug, name, region } = JSON.parse(target?.value);
      const locationsBySameSlug = locations?.filter(
        (q) => q?.slug === slug,
      );
      const hasFilters = locationsBySameSlug?.length > 0;
      // Regions
      const filteredRegions = regions.filter(
        (r) => lowerCase(r) !== lowerCase(region),
      );
      const filteredLocations = locations.filter(
        (q) => q?.slug !== slug,
      );

      if (!hasFilters) {
        setLocations([...locations, { slug, name, region }]);
      } else {
        setRegions([...filteredRegions]);
        setLocations([...filteredLocations]);
      }

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      console.error('onChangeCity', error);
    }
  };
  // FILTERs: by TYPES
  const onChangeTypes = ({ target }) => {
    try {
      const { slug, name } = JSON.parse(target?.value);
      const typesBySameSlug = types?.filter(
        (t) => t?.slug === slug,
      );
      const typesByDifferentSlug = types?.filter(
        (t) => t?.slug !== slug,
      );
      const hasType = typesBySameSlug?.length > 0;

      if (!hasType) setTypes([...types, { slug, name }]);
      else setTypes([...typesByDifferentSlug]);

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      console.error('onChangeTypes', error);
    }
  };
  // Utility: group cities by region
  const groupedByRegion = (cities) => {
    if (cities?.length > 0) {
      // Sort city by region
      const sortedCity = sortBy(cities, 'attributes.region');
      // Then group them by region
      const groupedByCity = groupBy(
        sortedCity,
        'attributes.region',
      );
      const regions = Object.entries(groupedByCity);
      const list = Object.values(groupedByCity);

      return regions.map((region, i) => {
        return {
          slug: region[0],
          name: region[0],
          list: sortBy(list[i], 'attributes.name'),
        };
      });
    }
  };

  const onChangeRegion = ({ slug, name, list }) => {
    try {
      const lowercaseName = lowerCase(name);

      const regionsWithSameSlug = regions?.filter(
        (r) => lowerCase(r) === lowercaseName,
      );

      const regionsDifferentSlug = regions?.filter(
        (r) => lowerCase(r) !== lowercaseName,
      );

      const locationsDifferentSlug = uniqBy(
        locations?.filter(
          ({ region }) => lowerCase(region) !== lowercaseName,
        ),
        'slug',
      );

      const filteredCitiesByRegion = cities?.filter(
        ({ attributes }) => {
          const { region } = attributes;
          return lowerCase(region) === lowercaseName;
        },
      );

      if (regionsWithSameSlug?.length > 0) {
        setRegions([...regionsDifferentSlug]);
        setLocations([...locationsDifferentSlug]);
      } else {
        setRegions([...regions, lowercaseName]);

        const test = filteredCitiesByRegion.map(
          ({ attributes }) => {
            const { name, slug, region } = attributes;
            return {
              name,
              slug,
              region,
            };
          },
        );

        const mergedValues = unionBy(locations, test, 'slug');
        setLocations(mergedValues);

        window?.history.replaceState(
          {},
          document.title,
          window?.location?.pathname,
        );
      }
    } catch (error) {
      console.error('onChangeRegion', error);
    }
  };

  return (
    <>
      <NextSeo
        title={`Aditus | ${title}`}
        description={description}
      />
      <DefaultLayout showBreadcrumbs={false}>
        <div className="relative flex flex-row h-auto my-5 md:space-x-8">
          {/* FILTERS CONTAINER */}
          <div className="hidden shrink-0 border-r-2 border-slate-100 dark:border-[#1e1e1e] lg:flex lg:w-3/12">
            <div className="w-full">
              <div className="sticky flex flex-col w-full pr-8 top-24">
                <div className="flex flex-col pb-40 mb-5 space-y-4">
                  <FiltersByTypes
                    data={filters}
                    isSuccess={isFiltersSuccess}
                    isLoading={isFiltersLoading}
                    isError={isFiltersError}
                    types={types}
                    onChangeTypes={onChangeTypes}
                  />

                  <FiltersByCities
                    collapsed={true}
                    data={cities}
                    isSuccess={isCitiesSuccess}
                    isLoading={isCitiesLoading}
                    isError={isCitiesError}
                    regions={regions}
                    setRegions={setRegions}
                    locations={locations}
                    setLocations={setLocations}
                    onChangeRegion={onChangeRegion}
                    onChangeCity={onChangeCity}
                  />

                  {[...locations, ...types].length > 0 && (
                    <button
                      type="text"
                      onClick={onResetFilter}
                      className="w-full text-sm font-bold text-right uppercase"
                    >
                      Reset
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* PRODUCTS CONTAINER */}
          <div className="w-full min-h-screen md:px-3 lg:w-8/12">
            <div className="space-y-4 text-gray-700 dark:text-white">
              {/* MAIN TITLE */}
              <h1
                className={classNames(
                  'text-lg md:text-3xl',
                  'font-title font-semibold',
                )}
              >
                {t(`site.products.${[type]}`)}
              </h1>
              {products?.data?.length > 0 && (
                <div
                  className={classNames(
                    'relative mb-8 w-full text-lg',
                    'flex items-center justify-between',
                  )}
                >
                  <div>
                    <span className="mr-2">
                      {t('generic.labels.products_found')}
                    </span>
                    <span className="font-bold">
                      {products?.data?.length}
                    </span>
                  </div>
                  <Button
                    onClick={onOpenDrawerFilters}
                    className={classNames(
                      'h-10 space-x-2',
                      'flex rounded-sm md:hidden',
                      'text-center text-sm uppercase',
                    )}
                  >
                    <span>{t('buttons.filters')}</span>
                    <TuneIcon fontSize="small" />
                  </Button>
                </div>
              )}
              <FiltersChips
                chips={chips}
                onDeleteFilterChip={onDeleteFilterChip}
              />
            </div>

            {isProductsSuccess && (
              <Virtuoso
                followOutput={false}
                className="z-0 mt-6 mb-20"
                components={{
                  EmptyPlaceholder: () =>
                    (!isProductsLoading || !isProductsFetching) &&
                    products?.data?.length === 0 && (
                      <Alert
                        severity="warning"
                        className="my-6"
                        action={
                          <Button
                            size="small"
                            color="inherit"
                            className="hidden px-4 md:flex"
                            onClick={onResetFilter}
                          >
                            Reset
                          </Button>
                        }
                      >
                        <AlertTitle>
                          {t('generic.labels.attention')}
                        </AlertTitle>
                        {t('generic.labels.no_results')}
                      </Alert>
                    ),
                  Footer: () => (
                    <>
                      {isProductsLoading ||
                        (isProductsFetching && (
                          <div className="flex items-center justify-center w-full h-40 mx-auto">
                            <CircularProgress
                              thickness={4}
                              size={20}
                            />
                          </div>
                        ))}
                    </>
                  ),
                }}
                data={products?.data}
                overscan={limit}
                useWindowScroll
                endReached={() => {
                  // TO FIX ASAP
                }}
                itemContent={(i, p) => {
                  return (
                    <div key={i}>
                      <Card
                        key={p?.attributes?.id + i}
                        product={p?.attributes}
                        loading={isProductsFetching}
                      />
                      <div className="h-6" />
                    </div>
                  );
                }}
              />
            )}
          </div>
        </div>
        {/* DRAWER FILTER */}
        {!isDesktop && (
          <>
            <DrawerFilters
              types={types}
              cities={cities}
              regions={regions}
              setRegions={setRegions}
              groupedByRegion={groupedByRegion}
              filters={filters}
              locations={locations}
              setLocations={setLocations}
              onResetFilter={onResetFilter}
              onChangeTypes={onChangeTypes}
              onChangeCity={onChangeCity}
              filtersLoading={isCitiesLoading}
              isCitiesLoading={isCitiesLoading}
              isFiltersLoading={isFiltersLoading}
              drawerFiltersOpen={drawerFiltersOpen}
              setDrawerFiltersOpen={setDrawerFiltersOpen}
            />
            <div
              className={classNames(
                'bg-primary',
                'z-50 h-12 md:h-14',
                'fixed bottom-0 left-0 right-0',
                'flex items-center justify-center',
              )}
            >
              <Button
                onClick={onOpenDrawerFilters}
                className={classNames(
                  'flex justify-center',
                  'h-10 w-full md:h-12',
                  'space-x-2 rounded-sm',
                  'text-center text-sm uppercase text-white',
                )}
              >
                <span>{t('buttons.filters')}</span>
                <TuneIcon fontSize="small" />
              </Button>
            </div>
          </>
        )}
      </DefaultLayout>
    </>
  );
}
